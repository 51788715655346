import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navrbar';
import { AppBar, Box, Toolbar } from '@mui/material';
import logo from '../assets/logo.png';
import Footer from './Footer';

const OutletComponent = () => {
    return (
        <div>
            <AppBar className='header' position="static">
                <Toolbar>
                    <img className='logo' src={logo} />
                    <h1>
                        Premier League Predictions
                    </h1>
                </Toolbar>
            </AppBar>
            <Navbar />

            <main>
                <Outlet />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Footer />
                </Box>
            </main>

        </div>
    );
};

export default OutletComponent;
