import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, LinearProgress, Box } from '@mui/material';
import './PredictionCard.css'
import { Fixture } from '../types/Fixture';
import { teamColors } from './PostionProbabilities';
import { FaChevronDown, FaChevronUp, FaLongArrowAltRight } from 'react-icons/fa';

interface PredictionCardProps {
    fixture: Fixture
}

// # draw
// # away win
// # home win

const PredictionCard: React.FC<PredictionCardProps> = ({
    fixture
}) => {

    const [expandedRow, setExpandedRow] = useState(null);
    // const updatedSecondTeam = fixtures.filter((curTeam) => {
    //   return curTeam.firstTeam === firstTeam;
    // });


    const handleExpand = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const handleClose = () => {
        setExpandedRow(null);
    };

    return (
        <Card variant="outlined" style={{ margin: '20px' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Predicted Score:</Typography>
                        <Typography variant="h6">
                            {fixture.teams.home.name} <b>{fixture.predicted_home_goals.toFixed(2)} - {fixture.predicted_away_goals.toFixed(2)}</b> {fixture.teams.away.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">Outcome Probabilities:</Typography>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress sx={{
                                    height: 16,
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: teamColors[fixture.teams.home.name], // Custom color for Home Win
                                    },
                                }}
                                    variant="determinate" value={Number((fixture.outcome_probabilities[2] * 100).toFixed(0))} />
                            </Box>
                            <Box minWidth={100}>
                                <Typography variant="body2" color="textSecondary">
                                    {`${(fixture.outcome_probabilities[2] * 100).toFixed(0)}% Home Win`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress
                                    sx={{
                                        height: 16,
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: 'grey'
                                        },
                                    }} variant="determinate" value={fixture.outcome_probabilities[0] * 100} />
                            </Box>
                            <Box minWidth={100}>
                                <Typography variant="body2" color="textSecondary">
                                    {`${(fixture.outcome_probabilities[0] * 100).toFixed(0)}% Draw`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress sx={{
                                    height: 16,
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: teamColors[fixture.teams.away.name], // Custom color for Home Win
                                    },
                                }} variant="determinate" value={fixture.outcome_probabilities[1] * 100} />
                            </Box>
                            <Box minWidth={100}>
                                <Typography variant="body2" color="textSecondary">
                                    {`${Number((fixture.outcome_probabilities[1] * 100).toFixed(0))}% Away Win`}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <div className="flex float-right">
                    <button
                        onClick={() => handleExpand(0)}
                        className="view-match-stats-button border-[1px] px-4 py-1 group-hover:text-white max-lg:hidden">
                        View Match Stats
                        <div className="chevron group-hover:-translate-x-[10px] lg:group-hover:translate-x-[10px] lg:ms-3 transition-all duration-200  group-hover:text-white">
                            {expandedRow === 0 ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                    </button>
                </div>

                {expandedRow === 0 && (
                    <div>
                        <Card style={{ margin: '20px', padding: '20px', width: '100%' }}>
                            <CardContent>
                                <Typography variant="h5" component="div" gutterBottom>
                                    Match Data
                                </Typography>
                                <Grid container spacing={2}>
                                    {Object.entries(fixture.match_data).map(([key, value]) => (
                                        <Grid item xs={6} sm={4} md={3} key={key}>
                                            <Typography variant="body1" color="textSecondary">
                                                {key.replace(/_/g, ' ')}:
                                            </Typography>
                                            <Typography variant="h6">{value.toFixed(2)}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    </div>
                )}

            </CardContent>
        </Card>
    );
};

export default PredictionCard;
