import React, { useState } from 'react';
import './navbar.css';
import { NavLink } from 'react-router-dom';
import { IoIosMenu } from "react-icons/io";
import { MdOutlineClose } from "react-icons/md";
import PremierSmall from '../assets/teamLogos/P.png';
import useSticky from '../hooks/useSticky';

const Navbar = () => {
    const [isNav, setIsNav] = useState(false);
    const { headerSticky } = useSticky();

    const handleNavToggle = () => {
        setIsNav(!isNav);
    }
    return (
        <>
            <nav className="subNav js-sub-nav shadow-nav lg:block " role="menubar">
                <ul className="showMoreEnabled  space-x-3 me-5 flex flex-row justify-end  ">
                    <li data-nav-index="0"><NavLink to="/" className={({ isActive }) => isActive ? "px-3 activeLine text-sm" : 'text-sm px-3'} data-link-index="0" role="menuitem">Home</NavLink></li>
                    <li data-nav-index="1"><NavLink to="/table" className={({ isActive }) => isActive ? "px-3 activeLine text-sm" : 'text-sm px-3'} data-link-index="0" role="menuitem">Predicted Table</NavLink></li>
                    <li data-nav-index="2"><NavLink to="/fixtures" className={({ isActive }) => isActive ? "px-3 activeLine text-sm" : 'text-sm px-3'} data-link-index="1" role="menuitem">Fixtures</NavLink></li>
                    <li data-nav-index="3"><NavLink to="/about" className={({ isActive }) => isActive ? "px-3 activeLine text-sm" : 'text-sm px-3'} data-link-index="3" role="menuitem">About</NavLink></li>
                </ul>
            </nav>
            {/* <nav className="subNav js-sub-nav shadow-nav hidden  md:block" role="menubar">
                <ul className="showMoreEnabled  space-x-3 me-5 flex flex-row justify-end  ">
                    <li data-nav-index="0"><NavLink to="/" className={({ isActive }) => isActive ? "px-3 activeLine text-sm" : 'text-sm px-3'} data-link-index="0" role="menuitem">Predicted Table</NavLink></li>
                    <li data-nav-index="1"><NavLink to="/fixtures" className={({ isActive }) => isActive ? "px-3 activeLine text-sm" : 'text-sm px-3'} data-link-index="1" role="menuitem">Fixtures</NavLink></li>
                    <li data-nav-index="7"><NavLink to="/about" className={({ isActive }) => isActive ? "px-3 activeLine text-sm me-8" : 'text-sm px-3 me-8'} data-link-index="7" role="menuitem">About</NavLink></li>
                </ul>
            </nav> */}
            {/* <div className=' hidden max-md:block w-full '>

                <div>
                    <div className={`${headerSticky ? 'fixed w-full z-50' : ''} h-[70px] bg-[#37003c] flex justify-between items-center`}>
                        <div onClick={handleNavToggle} className={`${isNav ? 'bgMenu' : ''} text-4xl flex items-center text-white h-[70px] px-4 ms-auto`}>
                            {isNav ? <MdOutlineClose /> : <IoIosMenu />}
                        </div>
                    </div>

                    <nav className={`fixed top-[70px] left-0 w-full z-20 js-sub-nav shadow-navSm h-[calc(100vh-70px)] ${isNav ? 'block slide-in' : 'hidden'}`} role="menubar">
                        <ul className="space-y-5 flex flex-col justify-center items-center text-center font-bold ">
                            <li onClick={handleNavToggle} className="border-b-2 pb-2 pt-4 w-full  border-t-2 border-t-[#ff2882]" data-nav-index="0">
                                <NavLink to="/" data-link-index="0" role="menuitem">Predicted Table</NavLink>
                            </li>
                            <li onClick={handleNavToggle} className="border-b-2 py-2 w-full" data-nav-index="1">
                                <NavLink to="/fixtures" data-link-index="1" role="menuitem">Fixtures</NavLink>
                            </li>
                            <li onClick={handleNavToggle} className="border-b-2 py-2 w-full" data-nav-index="7">
                                <NavLink to="/video" data-link-index="7" role="menuitem">About</NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div> */}
        </>
    );
}


export default Navbar;
