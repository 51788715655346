// src/components/PredictionsTable.tsx
import React, { useEffect, useState } from 'react';
import { Team } from '../types/Team';
import './PremierLeagueTable.css';
import { PositionProbabilities } from '../types/PositionalProbability';
import "ag-charts-enterprise";
import PremierLeagueTable from './PremierLeagueTable';
import './tables.css';

const PredictionsTable: React.FC = () => {


    return (
        <div>
            <PremierLeagueTable />
        </div>
    );
};

export default PredictionsTable;