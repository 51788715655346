// src/components/PredictionsTable.tsx
import React, { } from 'react';
import splash from '../assets/splash.png'
import mobileSplash from '../assets/mobileSplash.png'
import { Box, Typography, Container, List, ListItem, ListItemText, Grid } from '@mui/material';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TableChartIcon from '@material-ui/icons/TableChart';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import BarChartIcon from '@material-ui/icons/BarChart';


const Home: React.FC = () => {


    return (
        <div className='home-container'>
            <Container className='home-text-container-one' maxWidth="lg">
                <Box>
                    <Typography variant="body1" paragraph>
                        Using Machine Learning to predict Premier League Fixtures
                    </Typography>
                </Box>
            </Container>
            <img src="./path-to-your-image/splash-desktop.jpg" className="splash-image image-desktop image-mobile" alt="Splash" />
            <Container className='home-text-container-main' maxWidth="lg">
                <Box my={4}>
                    <Typography variant="body1" paragraph>
                        Harnessing the power of machine learning and AI, our project uses a decade's worth of Premier League data and advanced algorithms to predict match outcomes for the 24/25 Premier League season using previous records, form, head-to-head performances and much more.
                    </Typography>
                </Box>
            </Container>
            <Container className="home-text-container" maxWidth="lg">
                <Box my={4} className="grid-container">
                    <Box className="grid-item">
                        <Box className="icon-box">
                            <CheckCircleIcon className="icon" />
                            <Typography variant="body1">
                                86% Prediction Accuracy
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="grid-item">
                        <Box className="icon-box">
                            <TableChartIcon className="icon" />
                            <Typography variant="body1">
                                Predicted Final 24/25 Season Table
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="grid-item">
                        <Box className="icon-box">
                            <SportsSoccerIcon className="icon" />
                            <Typography variant="body1">
                                Predicted Expected Goals for each Fixture
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="grid-item">
                        <Box className="icon-box">
                            <BarChartIcon className="icon" />
                            <Typography variant="body1">
                                Predicted Probability Outcomes for each Fixture
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default Home;