import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PredictionsTable from './components/PredictionsTable';
import PostionProbabilities from './components/PostionProbabilities';
import { AppBar, Toolbar, Typography } from '@mui/material';
import Fixtures from './components/Fixtures';
import './fonts.css';
import './App.css'
import Layout from './components/Layout';
import About from './components/About';
import Home from './components/Home';

function App() {
  return (
    <div className='app-container'>
      <Router>
        
        {/* <Toolbar className='position-probabilities-toolbar'>
          <PostionProbabilities />
        </Toolbar> */}
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/table" element={<PredictionsTable />} />
            <Route path="/fixtures" element={<Fixtures />} />
            <Route path="/about" element={<About />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
