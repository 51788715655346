import React, { useEffect, useState } from 'react';
import './tables.css';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import Premier from '../../../assets/teamLogos/premier.png'
// import { Tooltip } from 'react-tooltip';
// import FirstTeamFilters from './FirstTeamFilters';
import FirstTeamMd from './FirstTeamMd';
import FirstTeamSm from './FirstTeamSm';
import { firstTeamData } from './FristTeamData';
import { fetchFinalTable, fetchPositionProbabilities } from '../services/firebaseService';
import { FinalTableTeam } from '../types/FinalTableTeam';
import Loader from './Loader';
import { PositionProbabilities } from '../types/PositionalProbability';
import BarChartComponent from './BarChartComponent';



const FirstTeamTable = () => {
    const [expandedRow, setExpandedRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const [positionProbabilities, setPositionProbabilities] = useState<PositionProbabilities[]>([]);

    const handleExpand = (index: any) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const [finalTable, setFinalTable] = useState<FinalTableTeam[]>([]);

    useEffect(() => {
        const getPositionProbabilities = async () => {
            console.log('in fetch');
            const data = await fetchPositionProbabilities();
            // const configuredPositionProbabilities = configurePositionProbabilities(data);
            // console.log({ configuredPositionProbabilities });
            console.log({ data })
            setPositionProbabilities(data);
        };

        getPositionProbabilities();
    }, []);

    useEffect(() => {
        const getFinalTable = async () => {
            console.log('in fetch');
            const data = await fetchFinalTable();
            data.sort((a: { points: number; }, b: { points: number; }) => b.points - a.points)
            data.map((x: any, i: any) => {
                x.position = i + 1;
            })
            setFinalTable(data);
            setLoading(false);
        };

        getFinalTable();
    }, []);

    return (
        <>
            {loading ? <Loader /> :

                <div className="overflow-x-auto">
                    <div className='bg-table bg-cover bg-no-repeat relative  tableHerp  p-4 w-full h-[200px]  md:h-[250px] ' >
                        <header className="">
                            <h1 className="text-[3.5rem] md:text-[4.5rem] lg:text-[5rem] mt-10 font-bold text-white prediction-table-text">Predicted Final 24/25 Table</h1>
                        </header>
                    </div>

                    <table className="min-w-full bg-white max-lg:hidden">
                        <thead className="tableHead text-sm ">
                            <tr>
                                <th className="py-1 px-4">Position</th>
                                <th className="py-1 min-w-[300px]  text-start ps-[50px]">Club</th>
                                <th className="py-1 px-4">Played</th>
                                <th className="py-1 px-4">Won</th>
                                <th className="py-1 px-4">Lost</th>
                                <th className="py-1 px-4">Drawn</th>
                                <th className="py-1 px-4">GF</th>
                                <th className="py-1 px-4">GA</th>
                                <th className="py-1 px-4">GD</th>
                                <th className="py-1 px-4">Points</th>
                                <th className="py-1 px-4">More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {finalTable.map((team: FinalTableTeam, index) => (
                                <React.Fragment key={team.position}>
                                    <tr
                                        onClick={() => handleExpand(index)}
                                        className={`border-b text-center`}>
                                        <td
                                            data-tooltip-id="dot"
                                            data-tooltip-content="Previous Position"
                                            data-tooltip-variant="light"
                                            data-tooltip-place="top-end"

                                            // className="py-2 pe-4 cursor-pointer">{team.position} <span
                                            className="py-2 pe-4 cursor-pointer">{team.position} <span

                                                className="league-table__movement none"> <span className="league-table__tooltip-container tooltipContainer tooltip-left" role="tooltip"> <span className="league-table__tooltip-content tooltip-content"> <span className="league-table__result-highlight"> </span> </span> </span> </span>
                                            {/* <Tooltip id="dot" /> */}
                                        </td>
                                        <td className="py-2 min-w-[300px] text-start ps-[50px]  cursor-pointer">
                                            <img className='w-8 h-8 inline-block ' src={teamLogos[team.team_name]} alt={team.team_name} />
                                            <span className='teamName hover:underline text-[1.2rem] text-[#37003c]'>{team.team_name}</span></td>
                                        <td className="py-2 px-4">{team.played}</td>
                                        <td className="py-2 px-4">{team.won}</td>
                                        <td className="py-2 px-4">{team.lost}</td>
                                        <td className="py-2 px-4">{team.drawn}</td>
                                        <td className="py-2 px-4">{team.gf}</td>
                                        <td className="py-2 px-4">{team.ga}</td>
                                        <td className="py-2 px-4">{team.gd}</td>
                                        <td className="py-2 px-4 font-bold text-lg text-[#37003c] ">{team.points}</td>
                                        {/* <td className="py-2 px-4  cursor-pointer "><img className='w-8 h-8 mx-auto border-2  rounded-full' src={team.next} alt="" /></td> */}
                                        <td className="py-2 px-4  cursor-pointer">
                                            <button onClick={() => handleExpand(index)}>
                                                {expandedRow === index ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                    </tr>
                                    {expandedRow === index && (
                                        <tr >
                                            <td colSpan={12} className="p-4">
                                                <div className='flex justify-between items-center w-[85%]  mx-auto'>
                                                    {positionProbabilities
                                                        .filter(x => x.id === finalTable[expandedRow].team_name)
                                                        .map(teamData => (
                                                            <div key={teamData.id} className="team-chart">
                                                                <BarChartComponent teamData={teamData} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <FirstTeamMd positionProbabilities={positionProbabilities} finalTable={finalTable} />
                    <FirstTeamSm positionProbabilities={positionProbabilities} finalTable={finalTable} />
                </div>
            }
        </>
    );
};

export default FirstTeamTable;

export const teamLogos: any = {
    "Manchester United": "https://media.api-sports.io/football/teams/33.png",
    "Newcastle": "https://media.api-sports.io/football/teams/34.png",
    "Bournemouth": "https://media.api-sports.io/football/teams/35.png",
    "Fulham": "https://media.api-sports.io/football/teams/36.png",
    "Wolves": "https://media.api-sports.io/football/teams/39.png",
    "Liverpool": "https://media.api-sports.io/football/teams/40.png",
    "Southampton": "https://media.api-sports.io/football/teams/41.png",
    "Arsenal": "https://media.api-sports.io/football/teams/42.png",
    "Everton": "https://media.api-sports.io/football/teams/45.png",
    "Leicester": "https://media.api-sports.io/football/teams/46.png",
    "Tottenham": "https://media.api-sports.io/football/teams/47.png",
    "West Ham": "https://media.api-sports.io/football/teams/48.png",
    "Chelsea": "https://media.api-sports.io/football/teams/49.png",
    "Manchester City": "https://media.api-sports.io/football/teams/50.png",
    "Brighton": "https://media.api-sports.io/football/teams/51.png",
    "Crystal Palace": "https://media.api-sports.io/football/teams/52.png",
    "Brentford": "https://media.api-sports.io/football/teams/55.png",
    "Ipswich": "https://media.api-sports.io/football/teams/57.png",
    "Nottingham Forest": "https://media.api-sports.io/football/teams/65.png",
    "Aston Villa": "https://media.api-sports.io/football/teams/66.png"
};
