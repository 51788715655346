import React, { useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import UpcomingMatches from "./Matches";
import { Fixture } from "../types/Fixture";
import { teamLogos } from "./PremierLeagueTable";
import PredictionCard from "./PredictionCard";
import { Card, CardContent, Grid, Typography } from "@mui/material";

interface MatchProps {
  fixture: Fixture;
}

const Match: React.FC<MatchProps> = ({ fixture }) => {

  return (
    <div className=" border-b 2xl:w-[60%] 2xl:mx-auto ">
      <div className="flex justify-between items-center cursor-pointer mt-5 max-[480px]:px-0   min-[560px]:px-4 min-[768px]:px-8  bg-gradient-to-r from-[#43A4FF] to-[#963CFF] group">
        <div className="lg:hidden"></div>
        <div className="margin-left-right-auto flex space-x-1 sm:space-x-4 py-3  w-[90%] md:w-[75%]   lg:w-[50%]">
          <p className="text-[#37003c] max-[600px]:text-[.8rem] text-[1.2rem]  max-[500px]:my-auto overflow-hidden font-medium text-white   text-end w-[33%] vertical-height-50 bold">
            {fixture.teams.home.name}
          </p>
          <div className="flex w-[33%] space-x-1 md:space-x-3 justify-center items-center">
            <img
              className="max-[600px]:h-[1.8rem] h-[2.3rem]"
              src={fixture.teams.home.logo}
              alt=""
            />
            {(fixture as any).status && (fixture as any).status.short == 'FT' ?
              <p className="bold max-[600px]:w-[2rem] max-[600px]:h-[2rem] w-[4rem] h-[2.3rem]   font-medium text-center max-[600px]:text-[.7rem] max-[600px]:pt-[5px] mt-1 text-[1.2rem]  overflow-hidden text-[#37003c] text-white border-[1px] rounded-sm ">
                {fixture.goals.home + '-' + fixture.goals.away}
              </p>
              :
              <p className="min-width-62 bold max-[600px]:w-[2rem] max-[600px]:h-[2rem] w-[4rem] h-[2.3rem]   font-medium text-center max-[600px]:text-[.7rem] max-[600px]:pt-[5px] mt-1 text-[1.2rem]  overflow-hidden text-[#37003c] text-white border-[1px] rounded-sm ">
                {new Date(new Date(fixture.fixture.date).setHours(new Date(fixture.fixture.date).getHours() + 1)).toISOString().substring(11, 16)}
              </p>
            }

            <img
              className="max-[600px]:h-[1.8rem] h-[2.3rem]"
              src={fixture.teams.away.logo}
              alt=""
            />
          </div>
          <p className="text-[#37003c] max-[600px]:text-[.8rem] max-[560px]:my-auto text-[1.2rem] overflow-hidden font-medium text-white  w-[33%] vertical-height-50 bold">
            {fixture.teams.away.name}
          </p>
        </div>

        <p className=" text-white text-[#37003c] max-lg:hidden bold">
          {fixture.fixture.venue.name}
        </p>
      </div>

      <PredictionCard fixture={fixture} />
    </div>
  );
}

export default Match;
