import React, { useEffect, useState } from 'react'
import MatchDay from './MatchDay';
import Match from './UpcomingMatches';
import { Fixture } from '../types/Fixture';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu, MenuListboxSlotProps } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { menuItemClasses } from '@mui/base/MenuItem';
import { CssTransition } from '@mui/base/Transitions';
import { PopupContext } from '@mui/base/Unstable_Popup';
import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface FixTableProps {
    fixtures: Fixture[];
}

const FixTable: React.FC<FixTableProps> = ({ fixtures }) => {
    const [matchRounds, setMatchRounds] = useState<string[]>([]);
    const [selectedRound, setSelectedRound] = useState<string>('');
    const [selectedTeam, setSelectedTeam] = useState<string>('Select Team');
    const [teams, setTeams] = useState<string[]>([]);
    const [filteredFixtures, setFilteredFixtures] = useState<{ date: string, fixtures: Fixture[] }[]>([]);
    const [fixturesByDate, setFixturesByDate] = useState<{ date: string, fixtures: Fixture[] }[]>([]);

    const handleRoundChange = (event) => {
        const round = event.target.value;
        setSelectedRound(round);
        setSelectedTeam('Select Team');
        const filteredFixturedTemp = filterFixturesByRound(fixturesByDate, round);
        setFilteredFixtures(filteredFixturedTemp);
    }

    const handleTeamChange = (event) => {
        const team = event.target.value;
        setSelectedTeam(team);
        setSelectedRound(matchRounds[0]);
        const filteredFixturedTemp = filterFixturesByTeam(fixturesByDate, team);
        setFilteredFixtures(filteredFixturedTemp);
    }

    const filterFixturesByRound = (fixturesByDate: { date: string, fixtures: Fixture[] }[], round: string) => {
        return fixturesByDate.map(dateGroup => {
            const filteredFixtures = dateGroup.fixtures.filter(fixture =>
                fixture.league.round === round
            );
            return { ...dateGroup, fixtures: filteredFixtures };
        }).filter(dateGroup => dateGroup.fixtures.length > 0);
    };

    const filterFixturesByTeam = (fixturesByDate: { date: string, fixtures: Fixture[] }[], team: string) => {
        return fixturesByDate.map(dateGroup => {
            const filteredFixtures = dateGroup.fixtures.filter(fixture =>
                fixture.teams.home.name === team || fixture.teams.away.name === team
            );
            return { ...dateGroup, fixtures: filteredFixtures };
        }).filter(dateGroup => dateGroup.fixtures.length > 0);
    };

    useEffect(() => {
        const configureMatchRounds = async () => {
            const matchRoundsTemp: string[] = [];
            fixtures.forEach(x => {
                matchRoundsTemp.push(x.league.round)
            });
            setMatchRounds(Array.from(new Set(matchRoundsTemp)));

            const teamsTemp: string[] = [];
            fixtures.forEach(x => {
                teamsTemp.push(x.teams.home.name);
            });
            teamsTemp.sort((a, b) => a.localeCompare(b))
            setTeams(Array.from(new Set(teamsTemp)));

            const grouped = fixtures.reduce((acc, fixture) => {
                const date = new Date(fixture.fixture.date).toISOString().split('T')[0]; // Extract date part
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(fixture);
                return acc;
            }, {});

            const groupedArray = Object.keys(grouped).map(date => ({
                date,
                fixtures: grouped[date]
            }));

            const today = new Date();

            // Filter fixtures to find the earliest match after today
            const futureFixtures = fixtures.filter(x => new Date(x.fixture.date) > today);
            const nextMatch = futureFixtures.reduce((earliest, current) => {
                return new Date(current.fixture.date) < new Date(earliest.fixture.date) ? current : earliest;
            }, futureFixtures[0]);

            const nextMatchRound = nextMatch ? nextMatch.league.round : matchRoundsTemp[0];

            setSelectedRound(nextMatchRound);
            const filteredFixturedTemp = filterFixturesByRound(groupedArray, nextMatchRound);
            setFilteredFixtures(filteredFixturedTemp);

            setFixturesByDate(groupedArray)
            // setFilteredFixtures(groupedArray);
        };

        configureMatchRounds();
    }, []);

    return (
        <div>
            <div className='border-b 2xl:w-[60%] 2xl:mx-auto p-4'>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="round-select-label">Round</InputLabel>
                    <Select
                        labelId="round-select-label"
                        id="round-select"
                        value={selectedRound}
                        onChange={handleRoundChange}
                        label="Round"
                    >
                        {matchRounds.map((round) => (
                            <MenuItem key={round} value={round}>
                                {round}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth style={{ marginTop: '16px' }}>
                    <InputLabel id="team-select-label">Team</InputLabel>
                    <Select
                        labelId="team-select-label"
                        id="team-select"
                        value={selectedTeam}
                        onChange={handleTeamChange}
                        label="Team"
                    >
                        {teams.map((team) => (
                            <MenuItem key={team} value={team}>
                                {team}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {
                filteredFixtures.map((fixtureGroup, i) => (
                    <div key={i}>
                        <div className=''>
                            <MatchDay matchDay={fixtureGroup.date} />
                        </div>
                        {fixtureGroup.fixtures.map((curMatch, j) => (
                            <Match key={j} fixture={curMatch} />
                        ))}
                    </div>
                ))
            }
            {/* <div className='max-md:hidden'>
        <MatchDay matchDay={'Sunday 18 Aug 2024'} />
    </div>
    <div className='md:hidden'>
        <MatchDay matchDay={'Sun 18 Aug 2024'} />
    </div> */}
            {/* 
    {
        fixtures.map((curMatch, i) => {
            return (
                <Match
                    fixture={curMatch} />
            )
        })
    } */}
        </div>

    )
}

export default FixTable
