import React, { useState } from 'react';
// import { Tooltip } from 'react-tooltip';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { firstTeamData } from './FristTeamData';
import { FinalTableTeam } from '../types/FinalTableTeam';
import { teamLogos } from './PremierLeagueTable';
import { premierLeagueTeamsShortNames } from './FirstTeamSm';
import { PositionProbabilities } from '../types/PositionalProbability';
import BarChartComponent from './BarChartComponent';

interface FirstTeamMdProps {
    finalTable: FinalTableTeam[];
    positionProbabilities: PositionProbabilities[]
}

const FirstTeamMd: React.FC<FirstTeamMdProps> = ({ finalTable, positionProbabilities }) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const handleExpand = (index: any) => {
        setExpandedRow(expandedRow === index ? null : index);
    };
    return (
        <div className='max-md:hidden  lg:hidden'>
            <table className="min-w-full bg-white ">
                <thead className="tableHead text-sm ">
                    <tr>
                        <th className="py-1 px-4">Position</th>
                        <th className="py-1 min-w-[130px]  text-start ps-[15px] ">Club</th>
                        <th className="py-1 pe-4">Played</th>
                        <th className="py-1 px-4">Won</th>
                        <th className="py-1 px-4">Lost</th>
                        <th className="py-1 px-4">Drawn</th>
                        <th className="py-1 px-4">GF</th>
                        <th className="py-1 px-4">GA</th>
                        <th className="py-1 px-4">GD</th>
                        <th className="py-1 px-4">Points</th>
                        <th className="py-1 px-4">More</th>
                    </tr>
                </thead>
                <tbody>
                    {finalTable.map((team: FinalTableTeam, index: any) => (
                        <React.Fragment key={team.position}>
                            {/* <tr className={`border-b-${team.border} border-b text-center`}> */}
                            <tr
                                onClick={() => handleExpand(index)}
                                className={`border-b text-center`}>
                                <td
                                    data-tooltip-id="dot"
                                    data-tooltip-content="Previous Position"
                                    data-tooltip-variant="light"
                                    data-tooltip-place="top-end"

                                    className="py-2 pe-4 cursor-pointer">{team.position} <span

                                        className="league-table__movement none"> <span className="league-table__tooltip-container tooltipContainer tooltip-left" role="tooltip"> <span className="league-table__tooltip-content tooltip-content"> <span className="league-table__result-highlight"> </span> </span> </span> </span>
                                    {/* <Tooltip id="dot" /> */}
                                </td>
                                <td className="py-2 min-w-[130px] text-start ps-[15px]  cursor-pointer">
                                    <img className='w-8 h-8 inline-block ' src={teamLogos[team.team_name]} alt={team.team_name} />
                                    <span className='teamName hover:underline text-[1.2rem] text-[#37003c]'>{premierLeagueTeamsShortNames[team.team_name]}</span></td>
                                <td className="py-2 pe-4">{team.played}</td>
                                <td className="py-2 px-4">{team.won}</td>
                                <td className="py-2 px-4">{team.lost}</td>
                                <td className="py-2 px-4">{team.drawn}</td>
                                <td className="py-2 px-4">{team.gf}</td>
                                <td className="py-2 px-4">{team.ga}</td>
                                <td className="py-2 px-4">{team.gd}</td>
                                <td className="py-2 px-4 font-bold text-lg text-[#37003c] ">{team.points}</td>
                                <td className="py-2 px-4  cursor-pointer">
                                    <button onClick={() => handleExpand(index)}>
                                        {expandedRow === index ? <FaChevronUp /> : <FaChevronDown />}
                                    </button>
                                </td>
                            </tr>
                            {expandedRow === index && (
                                <tr >
                                    <td colSpan={12} className="p-4">
                                        <div className='flex justify-between items-center w-[85%]  mx-auto'>
                                            {positionProbabilities
                                                .filter(x => x.id === finalTable[(expandedRow as any)].team_name)
                                                .map(teamData => (
                                                    <div key={teamData.id} className="team-chart">
                                                        <BarChartComponent teamData={teamData} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default FirstTeamMd

