import React, { useEffect, useState } from 'react';
import './fixtures.css';
import FixTable from './FixTable';
import { fetchFixtures } from '../services/firebaseService';
import { Fixture } from '../types/Fixture';
import Loader from './Loader';

import { styled } from '@mui/system';

const FIxtures = () => {
    const [fixtures, setFixtures] = useState<Fixture[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getFixtures = async () => {
            const data = await fetchFixtures(); // Call function directly without await
            data.slice(0, 5);
            setFixtures(data);
            setLoading(false);
        };

        getFixtures();
    }, []);
    return (
        <>
            {loading ? <Loader /> :
                <div className='display-inline-block' >
                    <div className='bg-table bg-cover bg-no-repeat relative  tableHerp  p-4 w-full h-[200px]  md:h-[250px] ' >
                        <header className="">
                            <h1 className="text-[3.5rem] md:text-[4.5rem] lg:text-[5rem] mt-10 font-bold text-white">Fixtures</h1>
                        </header>

                        <main className=" mx-auto mt-[21px] md:mt-[47px] lg:mt-[34px] z-10 ">
                            
                            <div className="p-top-2em mt-4 md:px-2">
                                
                                <FixTable fixtures={fixtures} />
                            </div>
                        </main>
                    </div>
                    {/* <FixTable/> */}

                </div>
            }
        </>
    )
}

export default FIxtures