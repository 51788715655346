import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FaArrowRightLong } from "react-icons/fa6";
import { FinalTableTeam } from '../types/FinalTableTeam';
import { teamLogos } from './PremierLeagueTable';
import { PositionProbabilities } from '../types/PositionalProbability';
import BarChartComponent from './BarChartComponent';

interface FirstTeamMdProps {
  finalTable: FinalTableTeam[];
  positionProbabilities: PositionProbabilities[]
}

const FirstTeamSm: React.FC<FirstTeamMdProps> = ({ finalTable, positionProbabilities }) => {
  const [expandedRow, setExpandedRow] = useState(null);

  const handleExpand = (index: any) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  return (
    <div>
      <div className='max-md:block hidden'>
        <table className="min-w-full bg-white ">
          <thead className="tableHead text-sm ">
            <tr>
              <th className="py-1 px-4"></th>
              <th className="py-1  text-start text-[13px] font-bold ">Club</th>
              <th className="py-1 ps-2 text-[14px] font-bold">Pl</th>
              <th className="py-1 ps-4 text-[13px] font-bold">W</th>
              <th className="py-1 ps-4 text-[13px] font-bold">D</th>
              <th className="py-1 ps-4 text-[13px] font-bold">L</th>
              <th className="py-1 ps-4 text-[13px] font-bold">GD</th>
              <th className="py-1 ps-4 text-[13px] font-bold">Pts</th>
              <th className="py-1 ps-4 text-[13px] font-bold">More</th>
            </tr>
          </thead>
          <tbody>
            {finalTable.map((team: FinalTableTeam, index: any) => (
              <React.Fragment key={team.position}>
                <tr
                onClick={() => handleExpand(index)}
                  className={`border-b  text-center`}>
                  <td
                    data-tooltip-id="dot"
                    data-tooltip-content="Previous Position"
                    data-tooltip-variant="light"
                    data-tooltip-place="top-end"
                    className="py-2 pe-4 cursor-pointer">{team.position}
                  </td>
                  <td className="py-2 min-w-[80px] text-start   cursor-pointer">
                    <img className='w-6 h-6 inline-block ' src={teamLogos[team.team_name]} alt={team.team_name} />
                    <span className='font-bold ms-3 hover:underline text-[.9rem] text-[#37003c] '>{premierLeagueTeamsShortNames[team.team_name]}</span></td>
                  <td className="py-2 ps-2 text-[13px]">{team.played}</td>
                  <td className="py-2 ps-4 text-[13px]">{team.won}</td>
                  <td className="py-2 ps-4 text-[13px]">{team.drawn}</td>
                  <td className="py-2 ps-4 text-[13px]">{team.lost}</td>
                  <td className="py-2 ps-4 text-[13px]">{team.gd}</td>
                  <td className="py-2 ps-4 font-bold text-lg text-[#37003c] text-[13px] ">{team.points}</td>
                  <td className="py-2 ps-4  cursor-pointer">
                    <button onClick={() => handleExpand(index)}>
                      {expandedRow === index ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr >
                    <td colSpan={12} className="p-4">
                      <div className='flex justify-between items-center w-[85%]  mx-auto'>
                        {positionProbabilities
                          .filter(x => x.id === finalTable[(expandedRow as any)].team_name)
                          .map(teamData => (
                            <div key={teamData.id} className="team-chart">
                              <BarChartComponent teamData={teamData} />
                            </div>
                          ))
                        }
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FirstTeamSm


export const premierLeagueTeamsShortNames = {
  "Manchester City": "MCI",
  "Liverpool": "LIV",
  "Arsenal": "ARS",
  "Tottenham": "TOT",
  "Chelsea": "CHE",
  "Manchester United": "MUN",
  "Newcastle": "NEW",
  "Brentford": "BRE",
  "Aston Villa": "AVL",
  "Leicester": "LEI",
  "West Ham": "WHU",
  "Nottingham Forest": "NFO",
  "Brighton": "BHA",
  "Wolves": "WOL",
  "Ipswich": "IPS",
  "Bournemouth": "BOU",
  "Fulham": "FUL",
  "Crystal Palace": "CRY",
  "Southampton": "SOU",
  "Everton": "EVE"
};
