import React from 'react';


function MatchDay({ matchDay }) {
  return (
    <div className='flex justify-between items-center mt-5'>
      <p className='text-[#37003c] font-extrabold md:font-bold text-[1rem] lg:text-[1.3rem]'>{new Intl.DateTimeFormat('en-GB', { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(matchDay))}
      </p>
    </div>

  );
}
export default MatchDay;