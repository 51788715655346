// src/components/Footer.tsx

import React from 'react';
import { Container, Typography, Link, Box } from '@mui/material';

const Footer: React.FC = () => {
    return (
        <Box sx={{ bgcolor: '#37003c', color: 'white', py: 3, position: 'absolute', width: '100%' }}>
            <Container maxWidth="lg">
                <Typography variant="body1" align="center">
                    © {new Date().getFullYear()} Viqas Hussain.
                </Typography>
                <Typography variant="body2" align="center">
                    <Link href="mailto:viqashussain@hotmail.co.uk" color="inherit">
                        Contact
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
