// src/services/predictionsService.ts
import db from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

export const fetchFixtures = async () => {
  try {
      const response = await fetch('https://us-central1-football-predictions-app.cloudfunctions.net/getAllFixtures',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({data:{}})
        }
      );
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data.result.data)
      return data.result.data; // Ensure this line returns the fetched data
  } catch (error) {
      console.error('Error fetching predictions:', error);
      return []; // Return an empty array or handle the error appropriately
  }
};

export const fetchFinalTable = async () => {
  try {
      const response = await fetch('https://us-central1-football-predictions-app.cloudfunctions.net/getFinalTable',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({data:{}})
        }
      );
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data)
      return data.result.data; // Ensure this line returns the fetched data
  } catch (error) {
      console.error('Error fetching finalTable:', error);
      return []; // Return an empty array or handle the error appropriately
  }
};

export const fetchPositionProbabilities = async () => {
  try {
      const response = await fetch('https://us-central1-football-predictions-app.cloudfunctions.net/getPositionProbabilities',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({data:{}})
        }
      );
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data.result.data)
      return data.result.data; // Ensure this line returns the fetched data
  } catch (error) {
      console.error('Error fetching getPositionProbabilities:', error);
      return []; // Return an empty array or handle the error appropriately
  }
};