import { useState, useEffect } from 'react';
import { fetchPositionProbabilities } from '../services/firebaseService';
import { PositionProbabilities } from '../types/PositionalProbability';
import './PostionProbabilities.css';
import BarChartComponent from './BarChartComponent';

const PostionProbabilities: React.FC = () => {

    const [teamsWithProbabilityOfBeingFirst, setTeamsWithProbabilityOfBeingFirst] = useState<any[]>([]);
    const [positionProbabilities, setPositionProbabilities] = useState<any[]>([]);

    const configurePositionProbabilities = (data: PositionProbabilities[]) => {
        const teamsWithProbabilityOfBeingFirst = data
            .filter(team => team["1"] && typeof team["1"] === 'number' && team["1"] > 0)
            .map(team => ({
                id: team.id,
                data: [
                    { type: 'Probability', value: Number(team["1"].toFixed(2)) },
                    { type: 'Remaining', value: 1 - (team["1"] as number) }
                ]
            }))
            .sort((a, b) => b.data.find(x => x.type === 'Probability')!.value - a.data.find(x => x.type === 'Probability')!.value)
            .slice(0, 4);

        setTeamsWithProbabilityOfBeingFirst(teamsWithProbabilityOfBeingFirst);

        // console.log(teamsWithProbabilityOfBeingFirst)

        setTeamsWithProbabilityOfBeingFirst(teamsWithProbabilityOfBeingFirst);
    };

    useEffect(() => {
        const getPositionProbabilities = async () => {
            console.log('in fetch');
            const data = await fetchPositionProbabilities();
            const configuredPositionProbabilities = configurePositionProbabilities(data);
            // console.log({ configuredPositionProbabilities });
            console.log({data})
            setPositionProbabilities(data);
        };

        getPositionProbabilities();
    }, []);

    return (
        <div>
            <p>Premier League Winning Probabilities</p>
            <div className="charts-container">
                {positionProbabilities.map(teamData => (
                    <div key={teamData.id} className="team-chart">
                        <BarChartComponent teamData={teamData} />
                    </div>
                ))}
                {/* <AgChartsReact options={{
                    data: teamsWithProbabilityOfBeingFirst,
                    title: {
                        text: "Probability of becoming Champions",
                    },
                    series: [
                        {
                            type: "radial-bar",
                            radiusKey: "id",
                            angleKey: "probability",
                            angleName: "Team",
                            // label: {
                            //     formatter: params => `${params.datum.id}: ${(params.datum[params.angleKey])}%`,
                            //     color: 'black'
                            // },
                            formatter: (params) => {
                                console.log(params)
                                return {
                                    fill: (teamColors as any)[params.datum.datum.id]
                                }
                            }
                        },
                    ],
                }} /> */}
                {/* {teamsWithProbabilityOfBeingFirst.map(team => (
                    <div key={team.id} className="chart-item">
                        <AgChartsReact
                            options={{
                                data: team.data,
                                title: {
                                    text: team.id,
                                    fontFamily: 'PremierSans-Heavy, Arial, Helvetica Neue, Helvetica, sans-serif',
                                    color: 'black'
                                },
                                background: {
                                    fill: "#00ff85",
                                },
                                series: [
                                    {
                                        type: 'donut',
                                        angleKey: 'value',
                                        innerRadiusRatio: 0.7,
                                        tooltip: {
                                            enabled: false
                                        },
                                        innerLabels: [
                                            {
                                                text: (team.data[0].value * 100 + '%').toString(),
                                                fontSize: 40,
                                                color: 'black',
                                                fontFamily: 'PremierSans-Heavy, Arial, Helvetica Neue, Helvetica, sans-serif'
                                            },
                                        ],
                                        showInLegend: false,
                                        formatter: (params) => {
                                            if (params.datum.type === 'Probability') {
                                                return {
                                                    stroke: getTeamColor(team.id),
                                                    fill: getTeamColor(team.id),
                                                }
                                            }
                                            else {
                                                return {
                                                    stroke: 'black',
                                                    fill: 'white',
                                                }
                                            }
                                        }
                                    },
                                ],
                            }}
                        />
                    </div>
                ))} */}
            </div>
        </div>
    )
}

export default PostionProbabilities;

export const teamColors = {
    'Arsenal': '#EF0107',          // Red
    'Aston Villa': '#95BFE5',      // Light Blue
    'Bournemouth': '#DA291C',      // Red
    'Brentford': '#C8102E',        // Red
    'Brighton': '#0057B8',         // Blue
    'Chelsea': '#034694',          // Blue
    'Crystal Palace': '#1B458F',   // Blue
    'Everton': '#003399',          // Blue
    'Fulham': '#FFFFFF',           // White
    'Ipswich': '#1A8DFF',          // Blue
    'Ipswich Town': '#1A8DFF',     // Blue
    'Leicester': '#003090',        // Blue
    'Liverpool': '#C8102E',        // Red
    'Manchester City': '#6CABDD',  // Sky Blue
    'Manchester United': '#DA291C',// Red
    'Newcastle': '#241F20',        // Black
    'Nottingham Forest': '#E53233',// Red
    'Southampton': '#D71920',      // Red
    'Tottenham': '#132257',        // Navy Blue
    'West Ham': '#7A263A',         // Claret
    'Wolves': '#FDB913',           // Gold
};

function getTeamColor(team: string): string {
    return (teamColors as any)[team];
}
