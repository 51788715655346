import React from 'react';
import './Loader.css'; // Make sure to create the CSS file
import logo from '../assets/logo.png';

const Loader = () => {
  return (
    <div className="loader">
      <img src={logo} alt="Loading" className="loader-logo" />
    </div>
  );
};

export default Loader;