import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { teamColors } from './PostionProbabilities';

const BarChartComponent = ({ teamData }) => {
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Position Probability Distribution for {teamData.id}
      </Typography>
      {Object.keys(teamData)
        .filter(key => key !== 'id' && key !== 'taskId' && key !== 'timestamp')
        .map(position => (
          <Box key={position} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="body2" sx={{ width: '5%', minWidth: 30 }}>
              {getOrdinalSuffix(position)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={teamData[position] * 100}
              sx={{
                width: '100%',
                mr: 2,
                height: 16,
                '& .MuiLinearProgress-bar': {
                    backgroundColor: teamColors[teamData.id], // Custom color for Home Win
                },
            }}
            />
            <Typography variant="body2" color="textSecondary" sx={{ width: '15%', minWidth: 35 }}>
              {(teamData[position] * 100).toFixed(0)}%
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default BarChartComponent;

function getOrdinalSuffix(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = num % 100;

    return num + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
}