import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';

const About = () => {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h3" gutterBottom>
          About Our Premier League Predictions Project
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to our Premier League Predictions Project, an innovative venture into the realm of sports analytics, AI, and data science. Our goal is to leverage the power of advanced machine learning algorithms to forecast match outcomes and scores with remarkable precision. This project encompasses various components, including data collection, model training, simulation, and visualization, all working in harmony to provide a comprehensive prediction system.
        </Typography>

        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Project Overview
          </Typography>
          <Typography variant="body1" paragraph>
            Our Premier League Predictions Project is a sophisticated system designed to predict the outcomes of Premier League matches with an impressive accuracy rate of 86%. This project is the culmination of rigorous data analysis, feature engineering, and model training, based on data from the Premier League over the last 10 years. We utilize historical match data to train our models, ensuring they are well-equipped to predict future outcomes.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Data Collection and Feature Engineering
          </Typography>
          <Typography variant="body1" paragraph>
            We collect a wealth of match statistics, ensuring a thorough analysis of each game. Our dataset includes detailed metrics such as:
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom> 
                  Shooting Metrics:
                </Typography>
                <ul>
                  <li>Home Shots on Goal</li>
                  <li>Home Shots off Goal</li>
                  <li>Home Total Shots</li>
                  <li>Home Blocked Shots</li>
                  <li>Home Shots Inside Box</li>
                  <li>Home Shots Outside Box</li>
                  <li>Away Shots on Goal</li>
                  <li>Away Shots off Goal</li>
                  <li>Away Total Shots</li>
                  <li>Away Blocked Shots</li>
                  <li>Away Shots Inside Box</li>
                  <li>Away Shots Outside Box</li>
                </ul>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Disciplinary and Possession Metrics:
                </Typography>
                <ul>
                  <li>Home Fouls</li>
                  <li>Home Corner Kicks</li>
                  <li>Home Offsides</li>
                  <li>Home Ball Possession</li>
                  <li>Home Yellow Cards</li>
                  <li>Home Red Cards</li>
                  <li>Home Goalkeeper Saves</li>
                  <li>Away Fouls</li>
                  <li>Away Corner Kicks</li>
                  <li>Away Offsides</li>
                  <li>Away Ball Possession</li>
                  <li>Away Yellow Cards</li>
                  <li>Away Red Cards</li>
                  <li>Away Goalkeeper Saves</li>
                </ul>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Passing Metrics:
                </Typography>
                <ul>
                  <li>Home Total Passes</li>
                  <li>Home Passes Accurate</li>
                  <li>Home Passes %</li>
                  <li>Away Total Passes</li>
                  <li>Away Passes Accurate</li>
                  <li>Away Passes %</li>
                </ul>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Recent Form and Performance Metrics:
                </Typography>
                <ul>
                  <li>Home Team Recent Form</li>
                  <li>Away Team Recent Form</li>
                  <li>Home Team Recent Goals Scored</li>
                  <li>Home Team Recent Goals Conceded</li>
                  <li>Away Team Recent Goals Scored</li>
                  <li>Away Team Recent Goals Conceded</li>
                </ul>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Head-to-Head Statistics:
                </Typography>
                <ul>
                  <li>Head-to-Head Home Wins</li>
                  <li>Head-to-Head Away Wins</li>
                  <li>Head-to-Head Draws</li>
                  <li>Head-to-Head Home Goals Scored</li>
                  <li>Head-to-Head Away Goals Scored</li>
                  <li>Head-to-Head Home Goals Conceded</li>
                  <li>Head-to-Head Away Goals Conceded</li>
                </ul>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Machine Learning Models
          </Typography>
          <Typography variant="body1" paragraph>
            We use various machine learning models, including Random Forest Classifiers and Neural Networks, to predict match outcomes and scores. These models are trained on historical data and continuously updated with new data to improve their accuracy. Our best model predicts the probabilities of win, lose, or draw outcomes for each match, while additional models predict the number of goals scored by each team.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Simulation and Visualization
          </Typography>
          <Typography variant="body1" paragraph>
            One of the key features of our project is the ability to simulate entire seasons. By running multiple simulations, we can generate probabilities for each team's final league position, giving insights into their likely performance over the season. These simulations take into account the variability and unpredictability inherent in football matches.
          </Typography>
          <Typography variant="body1" paragraph>
            To visualize the results, we use interactive charts and graphs. These visualizations help users understand the predictions and the underlying data. For example, bar charts display the probabilities of each team finishing in different league positions, while line charts show predicted scores and outcomes for upcoming matches.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Web Integration
          </Typography>
          <Typography variant="body1" paragraph>
            Our project includes a dynamic web interface where users can interact with the predictions. The web app features a splash page with a visually striking image representing the project, and detailed pages where users can explore match predictions, team performance probabilities, and more. The interface is designed to be user-friendly and informative, providing a seamless experience for football fans and data enthusiasts alike.
          </Typography>
        </Box>

        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Future Directions
          </Typography>
          <Typography variant="body1" paragraph>
            As we continue to develop our project, we aim to incorporate more advanced machine learning techniques, expand our dataset, and enhance our simulation capabilities. Our ultimate goal is to provide the most accurate and insightful football predictions available, leveraging the latest advancements in AI and data science.
          </Typography>
          <Typography variant="body1" paragraph>
            Join us in exploring the fascinating intersection of football, data science, and AI. Our Premier League Predictions Project is not just about predicting scores but also understanding the intricacies of the beautiful game.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
